import React, { useState } from 'react';
import { ThemeProvider, useTheme } from './ThemeContext';
import BirthdayCountdown from './BirthdayCountdown';
import BirthdayCelebration from './BirthdayCelebration';
import { Moon, Sun } from 'lucide-react';

const birthdate = '2001-09-01'; // Replace with the actual birthdate
const name = 'Lulu'; // Replace with the actual name

function App() {
    const { isDarkMode, toggleTheme } = useTheme();
    const [isCelebrating, setIsCelebrating] = useState(false);

    const handleCelebrate = () => {
        setIsCelebrating(true);
    };

    return (
        <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'} transition-colors duration-300`}>
            {!isCelebrating ? (
                <div className="container mx-auto px-4 py-8">
                    <header className="flex justify-between items-center mb-8">
                        <button
                            onClick={toggleTheme}
                            className={`p-2 rounded-full ${isDarkMode ? 'bg-yellow-400 text-gray-900' : 'bg-gray-800 text-white'}`}
                        >
                            {isDarkMode ? <Sun size={24} /> : <Moon size={24} />}
                        </button>
                    </header>
                    <main>
                        <BirthdayCountdown birthdate={birthdate} name={name} onCelebrate={handleCelebrate} />
                    </main>
                </div>
            ) : (
                <BirthdayCelebration
                    name={name}
                    age={new Date().getFullYear() - new Date(birthdate).getFullYear()}
                />
            )}
        </div>
    );
}

function AppWrapper() {
    return (
        <ThemeProvider>
            <App />
        </ThemeProvider>
    );
}

export default AppWrapper;