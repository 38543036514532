import React, { useState, useEffect, useCallback } from 'react';
import { differenceInSeconds, addYears, format } from 'date-fns';
import { PartyPopper, Gamepad } from 'lucide-react';
import madPhoto from './mad.png';
import Game from "./Game/Game";

const BirthdayCountdown = ({ birthdate, name, onCelebrate }) => {
    const [timeLeft, setTimeLeft] = useState({});
    const [nextBirthday, setNextBirthday] = useState(null);
    const [age, setAge] = useState(0);
    const [showGame, setShowGame] = useState(false);

    const calculateNextBirthday = useCallback(() => {
        const birthdateObj = new Date(birthdate);
        const today = new Date();
        let nextBday = new Date(today.getFullYear(), birthdateObj.getMonth(), birthdateObj.getDate());
        if (today > nextBday) {
            nextBday = addYears(nextBday, 1);
        }
        setNextBirthday(nextBday);
        setAge(nextBday.getFullYear() - birthdateObj.getFullYear() - 1);
    }, [birthdate]);

    useEffect(() => {
        calculateNextBirthday();
    }, [calculateNextBirthday]);

    useEffect(() => {
        const updateCountdown = () => {
            const now = new Date();
            if (nextBirthday) {
                const difference = differenceInSeconds(nextBirthday, now);

                if (difference <= 0) {
                    onCelebrate();
                } else {
                    setTimeLeft({
                        days: Math.floor(difference / (60 * 60 * 24)),
                        hours: Math.floor((difference % (60 * 60 * 24)) / (60 * 60)),
                        minutes: Math.floor((difference % (60 * 60)) / 60),
                        seconds: difference % 60,
                    });
                }
            }
        };

        const timer = setInterval(updateCountdown, 1000);

        return () => clearInterval(timer);
    }, [nextBirthday, onCelebrate]);

    const formatDate = (date) => format(date, 'MMMM do, yyyy');

    const TimeDisplay = ({ value, label }) => (
        <div className="flex flex-col items-center mx-2 bg-opacity-20 bg-white p-4 rounded-lg">
            <span className="text-6xl font-bold">{value.toString().padStart(2, '0')}</span>
            <span className="text-sm uppercase mt-2">{label}</span>
        </div>
    );

    const handleGameStart = () => {
        setShowGame(true);
    };

    const handleGameEnd = () => {
        setShowGame(false);
    };

    if (!nextBirthday) return <div>Loading...</div>;

    return (
        <div className="text-center">
            <div className="relative">
                <div className={`transition-opacity duration-500 ${showGame ? 'opacity-0 absolute inset-0 pointer-events-none' : 'opacity-100'}`}>
                    <h2 className="text-4xl font-bold mb-8">Countdown to {name}'s {age + 1}th birthday</h2>
                    <div className="flex justify-center mb-8">
                        <TimeDisplay value={timeLeft.days || 0} label="Days"/>
                        <TimeDisplay value={timeLeft.hours || 0} label="Hours"/>
                        <TimeDisplay value={timeLeft.minutes || 0} label="Minutes"/>
                        <TimeDisplay value={timeLeft.seconds || 0} label="Seconds"/>
                    </div>
                    <p className="text-2xl mb-8">
                        The big day is on {formatDate(nextBirthday)}!
                    </p>
                    <h2 className="text-2xl font-bold mb-2">Merde, ce n'est pas encore mon anniversaire</h2>
                    <img
                        className="max-w-2xl mx-auto mb-4"
                        alt="Je suis enervee" src={madPhoto}
                    />
                    <button
                        onClick={handleGameStart}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 flex items-center mx-auto mt-4"
                    >
                        <Gamepad size={24} className="mr-2"/>
                        Voulez-vous jouer en attendant ?
                    </button>
                </div>
                <div className={`transition-opacity duration-500 ${showGame ? 'opacity-100' : 'opacity-0 absolute inset-0 pointer-events-none'}`}>
                    {showGame && (
                        <>
                            <h2 className="text-3xl font-bold mb-4">Jouons en attendant !</h2>
                            <Game onGameEnd={handleGameEnd} />
                        </>
                    )}
                </div>
            </div>
            <button
                onClick={onCelebrate}
                className="bg-yellow-400 hover:bg-yellow-500 text-gray-900 font-bold py-2 px-4 rounded-full transition duration-300 flex items-center mx-auto mt-8"
            >
                <PartyPopper size={24} className="mr-2"/>
                Celebrate Now!
            </button>
        </div>
    );
};

export default BirthdayCountdown;
