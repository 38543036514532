import React, { useState, useCallback, useEffect } from 'react';
import { Heart } from 'lucide-react';
import { useTheme } from "../ThemeContext";
import mad from './mad.png'
import content from './content.png'
import plus_contente from './plus_contente.png'
import trop_contente from './trop_contente.png'
import super_contente from './super_contente.png'

const Game = () => {
    const { isDarkMode } = useTheme();
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const [happiness, setHappiness] = useState(0);
    const [timeLeft, setTimeLeft] = useState(60);
    const [gameState, setGameState] = useState('intro');

    const photos = [
        mad,
        content,
        plus_contente,
        trop_contente,
        super_contente,
    ];

    const messages = [
        "Est-ce que tu peux me faire un câlin s'il te plaît ?",
        "J'aime bien ! Plus de câlins s'il te plaît !",
        "Plus, plus !",
        "Tu me rends content",
        "J'aime bien tes câlins"
    ];

    const handleStartGame = () => {
        setGameState('playing');
    };

    const handlePet = useCallback(() => {
        if (gameState !== 'playing') return;
        setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % photos.length);
        setHappiness((prevHappiness) => Math.min(prevHappiness + 10, 100));
    }, [gameState, photos.length]);

    useEffect(() => {
        if (gameState !== 'playing') return;

        const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    setGameState(happiness >= 80 ? 'won' : 'lost');
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [gameState, happiness]);

    useEffect(() => {
        if (happiness >= 80 && gameState === 'playing') {
            setGameState('won');
        }
    }, [happiness, gameState]);

    const restartGame = useCallback(() => {
        setCurrentPhotoIndex(0);
        setHappiness(0);
        setTimeLeft(60);
        setGameState('intro');
    }, []);

    return (
        <div className={`text-center p-8 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-black'} rounded-xl shadow-2xl max-w-2xl mx-auto transition-colors duration-300`}>
            {gameState === 'intro' && (
                <div className="mb-8">
                    <img
                        src={photos[0]}
                        alt="Play with me"
                        className="w-80 h-60 object-cover mx-auto rounded-lg shadow-lg mb-4"
                    />
                    <p className="text-2xl font-bold mb-4">Joue avec moi!</p>
                    <button
                        onClick={handleStartGame}
                        className={`${isDarkMode ? 'bg-green-600 hover:bg-green-700' : 'bg-green-500 hover:bg-green-600'} text-white font-bold py-2 px-4 rounded-full transition duration-300`}
                    >
                        Commencer le jeu
                    </button>
                </div>
            )}

            {gameState === 'playing' && (
                <>
                    <div className="relative inline-block mb-8">
                        <img
                            src={photos[currentPhotoIndex]}
                            alt={`${currentPhotoIndex + 1}`}
                            className="w-80 h-60 object-cover cursor-pointer rounded-lg shadow-lg transition-transform duration-300 transform hover:scale-105"
                            onClick={handlePet}
                        />
                    </div>
                    <div className={`mb-4 ${isDarkMode ? 'bg-gray-700' : 'bg-white'} p-3 rounded-lg shadow-md`}>
                        <p className="text-lg font-semibold">{messages[currentPhotoIndex]}</p>
                    </div>
                    <button
                        onClick={handlePet}
                        className={`${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white font-bold py-2 px-4 rounded-full transition duration-300 mb-4`}
                    >
                        Faire un câlin ici
                    </button>
                    <p className="text-xl font-bold mb-4">Bonheur: {happiness}%</p>
                    <p className="text-lg mb-4">Temps restant: {timeLeft} seconds</p>
                </>
            )}

            {gameState === 'won' && (
                <div className="mt-6">
                    <p className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-green-400' : 'text-green-600'}`}>Congratulations! The photo is super happy!</p>
                    <Heart size={48} className="mx-auto mb-4 text-red-500" />
                    <button
                        onClick={restartGame}
                        className={`${isDarkMode ? 'bg-green-600 hover:bg-green-700' : 'bg-green-500 hover:bg-green-600'} text-white font-bold py-2 px-4 rounded-full transition duration-300`}
                    >
                        Play Again
                    </button>
                </div>
            )}

            {gameState === 'lost' && (
                <div className="mt-6">
                    <p className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-red-400' : 'text-red-600'}`}>Oh no! The photo needs more love!</p>
                    <button
                        onClick={restartGame}
                        className={`${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white font-bold py-2 px-4 rounded-full transition duration-300`}
                    >
                        Try Again
                    </button>
                </div>
            )}
        </div>
    );
};

export default Game;