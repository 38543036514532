import React, { useCallback, useRef, useEffect } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { Cake, Gift, Camera, Music } from 'lucide-react';

const canvasStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 999
}

const BirthdayCelebration = ({ name, age }) => {
    const refAnimationInstance = useRef(null);

    const getInstance = useCallback((instance) => {
        refAnimationInstance.current = instance;
    }, []);

    const makeShot = useCallback((particleRatio, opts) => {
        refAnimationInstance.current &&
        refAnimationInstance.current({
            ...opts,
            origin: { y: 0.7 },
            particleCount: Math.floor(200 * particleRatio)
        });
    }, []);

    const fire = useCallback(() => {
        makeShot(0.25, {
            spread: 26,
            startVelocity: 55
        });

        makeShot(0.2, {
            spread: 60
        });

        makeShot(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
        });

        makeShot(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
        });

        makeShot(0.1, {
            spread: 120,
            startVelocity: 45
        });
    }, [makeShot]);

    useEffect(() => {
        fire();
        const interval = setInterval(fire, 3000); // Fire confetti every 3 seconds
        return () => {
            clearInterval(interval);
            if (refAnimationInstance.current) {
                refAnimationInstance.current.reset();
            }
        };
    }, [fire]);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gradient-to-br from-pink-400 to-purple-600 overflow-hidden">
            <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
            <div className="text-center text-white z-10">
                <h2 className="text-6xl font-bold mb-8 animate-bounce">
                    Happy {age}th Birthday, {name}! 🎉
                </h2>
                <div className="grid grid-cols-2 gap-6 max-w-2xl mx-auto">
                    {[
                        { Icon: Cake, text: "Time for cake!" },
                        { Icon: Gift, text: "Open your gifts!" },
                        { Icon: Camera, text: "Capture memories!" },
                        { Icon: Music, text: "Dance to your favorite tunes!" }
                    ].map((item, index) => (
                        <div
                            key={index}
                            className="bg-white bg-opacity-20 p-6 rounded-lg shadow-lg flex flex-col items-center transform transition-all duration-500 hover:scale-105"
                            style={{
                                animation: `fadeInScale 0.5s ease-out ${index * 0.1}s both`
                            }}
                        >
                            <item.Icon size={48} className="mb-4 animate-pulse" />
                            <p className="text-lg">{item.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BirthdayCelebration;
